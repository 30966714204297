import React, { useEffect, useState } from 'react';
import axios from 'axios';

const OpenApiGenerator = () => {
  const [method, setMethod] = useState('POST');
  const [path, setPath] = useState('');
  const [summary, setSummary] = useState('My awesome method');
  const [requestBody, setRequestBody] = useState('');
  const [responseBody, setResponseBody] = useState('');
  const [openAPIResponse, setOpenAPIResponse] = useState('');

  const handleGenerate = async () => {
    try {
      const url = 'https://openapi.ilinykh.org/api/v1/generate';
      const data = {
        type: method,
        path: path.trim() || null,
        summary: summary.trim() || "My awesome method",
        requestBody: requestBody.trim() ? JSON.parse(requestBody) : null,
        responseBody: responseBody.trim() ? JSON.parse(responseBody) : {}
      };

      const response = await axios.post(url, data, {
        headers: { 'Content-Type': 'application/json' }
      });

      setOpenAPIResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
      setOpenAPIResponse(`Error: ${error.message}`);
    }
  };

  return (
    <div className="container">
      <h1>Easy openAPI generator</h1>
      <div className="line">

        <select value={method} onChange={(e) => setMethod(e.target.value)} className="dropdown line-element">
              <option value="POST">POST</option>
              <option value="GET">GET</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
        </select>

        <input type="text"
            value={path} 
            onChange={(e) => setPath(e.target.value)} 
            className="textarea one-line-text line-element" 
            placeholder="/api/v1/methodname"
          />
        <input type="text" 
          value={summary} 
          onChange={(e) => setSummary(e.target.value)} 
          className="textarea one-line-text line-element" 
          placeholder="Describe your method..."
        />
      </div>

      <div className="line">
        <div className='vertical-block'>
          <label>Request Body:</label>
          <textarea 
            value={requestBody} 
            onChange={(e) => setRequestBody(e.target.value)} 
            className="req-resp-textarea" 
            placeholder='{ "key": "value" }'
          />
        </div>

        <div className='vertical-block'>
          <label>Response Body:</label>
          <textarea 
            value={responseBody} 
            onChange={(e) => setResponseBody(e.target.value)} 
            className="req-resp-textarea" 
            placeholder='{ "key": "value" }'
          />
        </div>
        
      </div>

      <div className="line center">
        <button onClick={handleGenerate} className="button">Generate</button>
      </div>
      
      <div className="line">
        <textarea 
          value={openAPIResponse} 
          readOnly 
          className="textarea response-textarea"
          placeholder='Openapi will be here'
        />
      </div>
    </div>
  );
};

export default OpenApiGenerator;
