import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Registration from './pages/Registration';
import OpenApiGenerator from './OpenApiGenerator';
import './App.css';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<OpenApiGenerator />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/generator" element={<OpenApiGenerator />} />
        </Routes>
      </div>
    </Router>
  );
}

function Home() {
  return <h2>Easy openAPI generator</h2>;
}
export default App;
