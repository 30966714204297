import React, {useState} from 'react';
import axios from 'axios';

function Registration() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
    });

    const [registeredInfo, setRegisteredInfo] = useState('');

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Submit form data to your API or backend here
        const url = 'https://openapi.ilinykh.org/api/v1/public/register';
        await axios
            .post(url, formData, {
                headers: {'Content-Type': 'application/json'}
            })
            .then((response) => {
                setRegisteredInfo(response.data.message)
            })
            .catch((error) => {
                if (error.message) {
                    setRegisteredInfo(error.response.data.message);
                } else {
                    setRegisteredInfo('Server error');
                }
            });
    };

    return (
        <div>
            <h2>Registration Page</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>First name:</label>
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Last name:</label>
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Register</button>
            </form>
            <input
                value={registeredInfo}
            />
        </div>
    );
}

export default Registration;
